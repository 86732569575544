<template>
  <div class="esg">
    <nav-bar-esg />
    <router-view></router-view>
  </div>
</template>

<script>
import NavBarEsg from "@/components/template/esg/NavbarEsg.vue";

export default {
  name: "ESG",
  components: {NavBarEsg},
}
</script>

<style lang="scss" scoped>
.esg {
  display: flex;
  justify-content: space-between;
  padding: 150px 50px 56px 50px;
  min-height: 100vh;
  height: 100%;
  background: #F5F5F5;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    padding: 99px 20px 50px 20px;
  }

}
</style>